import clsx from 'clsx';
import Downshift from 'downshift';
import React, {
  useMemo, useRef, useState,
} from 'react';
import { Search } from 'react-feather';
import { useSelector } from 'react-redux';
import useDebounce from '@v1/lib/useDebounce';
import { LoaderBase } from '@v1/components/Loader';
import { interestTypes } from '@/constants';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import CategorySelect from '../CategorySelect/CategorySelect';
import OmnisearchResults from './OmnisearchResults';
import Chip from '../Chip';
import EntityText from '../EntityText';
import { Input } from '../Input';
import useOmnisearchResults from './useOmnisearchResults';
import styles from './Omnisearch.module.scss';

const SearchInput = ({
  onClick, search, inputOnChange, isLoading,
}) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div
    className={clsx({
      [styles.field]: true,
    })}
    onClick={onClick}
  >
    {isLoading ? <LoaderBase isLoading={isLoading} resetMargin size={18} /> : <Search size={18} strokeWidth={2} />}
    <div className={styles.inputContainer}>
      <Input
        autoFocus
        className={styles.input}
        onChange={inputOnChange}
        placeholder="Search by keyword"
        value={search}
      />
    </div>
  </div>
);

const Omnisearch = ({ downshift, onChange, value }) => {
  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    isOpen,
    toggleMenu,
  } = downshift;

  const inputRef = useRef(null);
  const inputProps = getInputProps({ ref: inputRef });

  const entityById = useSelector((state) => state.entities.byId);

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');

  const debouncedSearch = useDebounce(search, 250);

  const handleSearchInputChange = (inputValue, e) => {
    inputProps.onChange(e);
    setSearch(inputValue);
  };

  const omnisearchResults = useOmnisearchResults({ search: debouncedSearch, category });

  const handleDeleteValueByIndex = (index) => () => {
    const nextValue = [...value];
    nextValue.splice(index, 1);
    onChange(nextValue);
  };

  const handleClickSearchInput = () => {
    toggleMenu();
    if (!isOpen) {
      inputRef.current?.focus();
    }
  };

  const handleSelectCategory = (urn) => {
    setCategory(urn);
  };

  return (
    <div className={styles.container}>
      <div className={styles.fieldsContainer}>
        <SearchInput
          inputOnChange={handleSearchInputChange}
          search={search}
          onClick={handleClickSearchInput}
          isLoading={omnisearchResults.isLoading}
        />
        <CategorySelect value={category} onChange={handleSelectCategory} className={styles.categorySelect} />
      </div>
      {(value && !!value.length) && (
        <div className={styles.chipsContainer}>
          {value.map((item, i) => {
            const entity = entityById[item.id];

            return (
              <Chip
                key={item.id}
                onDelete={handleDeleteValueByIndex(i)}
                variant="contained"
                className={styles.chip}
                style={{
                  '--dynamic-category-color': (entity?.types || []).length > 0
                    ? `var(--category-${formatCategoryUrnToCssVar(entity.types[0])})` : 'hsl(var(--gray-ghost))',
                  '--dynamic-category-secondary-color':
                        (entity?.types || []).length > 0
                          ? `var(--category-${formatCategoryUrnToCssVar(entity.types[0])}-secondary)`
                          : 'hsl(var(--gray-light))',
                }}
                deleteIconClassName={styles.chipDeleteIcon}
              >
                <EntityText className={styles.chipText} item={item} />
              </Chip>
            );
          })}
        </div>
      )}
      {debouncedSearch && (
        <OmnisearchResults
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          omnisearchResults={omnisearchResults}
          value={value}
        />
      )}
    </div>
  );
};

const OmnisearchContainer = ({ onChange, ...props }) => {
  const value = useMemo(() => props.value || [], [props.value]);

  const handleSelection = (itemToBeAdded) => {
    const nextItem = {
      id: itemToBeAdded.entity_id || itemToBeAdded.id,
      name: itemToBeAdded.name,
      type: itemToBeAdded.entity_id ? interestTypes.entity : interestTypes.tag,
    };

    const hasItem = value.some((item) => item.id === nextItem.id);

    if (!hasItem) {
      const nextValue = [
        ...value,
        nextItem,
      ];

      return onChange(nextValue);
    }

    // Remove it from array
    const nextValue = value.filter((item) => item.id !== nextItem.id);

    return onChange(nextValue);
  };

  return (
    <Downshift onChange={handleSelection} selectedItem={null}>
      {(downshift) => (
        <Omnisearch {...downshift.getRootProps()} downshift={downshift} onChange={onChange} value={value} />
      )}
    </Downshift>
  );
};

export default OmnisearchContainer;

import React, { useMemo } from 'react';
import { api } from '@/constants';

import styles from './DashboardPanel.module.scss';
import { paramLabels } from '../../../constants/paramNames';

const PanelBodyError = ({ error }) => {
  const errorMessage = useMemo(() => {
    if (!error) return null;

    const words = Object.keys(paramLabels).join('|');
    const regex = new RegExp(`\\b(${words})\\b`, 'g');
    return error.replace(regex, (match) => paramLabels[match]);
  }, [error]);

  return (
    <div className={styles.panelErrorContainer}>
      {/* <AlertTriangle className={styles.panelErrorIcon} size={40} /> */}
      {errorMessage ? (
        <pre className={styles.panelErrorMessage}>{errorMessage}</pre>
      ) : (
        <div className={styles.panelErrorText}>
          This query returned no results.
        </div>
      )}
    </div>
  );
};

export default PanelBodyError;

import React, { useState } from 'react';
import CategoriesDataList from './CategoriesDataList';
import SelectedCategoryContent from './SelectedCategoryContent';
import styles from './PanelBodyExplore.module.scss';
import { useDashboardActionsContext } from '@/screens/Dashboard/DashboardActionsProvider';

const PanelBodyExplore = ({
  baseParams, updateHasMoreExplorePages, pagination, panelId, exploreControls,
}) => {
  const dashboardActions = useDashboardActionsContext();
  const [selectedCategory, setSelectedCategory] = useState(exploreControls?.selectedCategory);

  const handleSelectedCategory = (newSelectedCategory) => () => {
    setSelectedCategory(newSelectedCategory);
    dashboardActions.changePanelSettings(
      { panelId, nextSettings: { exploreControls: { selectedCategory: newSelectedCategory } } },
    );
  };

  return (
    <div className={styles.container}>
      {selectedCategory
        ? (
          <SelectedCategoryContent
            panelId={panelId}
            baseParams={baseParams}
            pagination={pagination}
            exploreControls={exploreControls}
            selectedCategory={selectedCategory}
            handleSelectedCategory={handleSelectedCategory}
            updateHasMoreExplorePages={updateHasMoreExplorePages}
          />
        )
        : <CategoriesDataList baseParams={baseParams} onSelect={handleSelectedCategory} />}
    </div>
  );
};

export default PanelBodyExplore;

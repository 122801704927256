import React, { useEffect } from 'react';
import { FieldsModal } from '@/components';
import { useScrollBlock, useToggle } from '@/hooks';

const QueryBuilderPopoverPanel = ({
  baseParams,
  close,
  color,
  onChange,
  onReset,
  onUpdate,
  params,
  paramNames,
  title,
  openPopover,
}) => {
  const isReadyToggle = useToggle();

  const handleReset = () => {
    onReset(paramNames);
    close();
  };

  const handleChange = ({ key, value }) => {
    const prevValue = params[key];
    onChange({ key, value });
    if (onUpdate) {
      onUpdate(prevValue, value);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(isReadyToggle.on, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const [blockScroll, allowScroll] = useScrollBlock();
  useEffect(() => {
    blockScroll();
    return () => {
      allowScroll();
    };
  }, []);

  return (
    <FieldsModal
      baseParams={baseParams}
      paramNames={paramNames}
      params={params}
      title={title}
      color={color}
      onChange={handleChange}
      onReset={handleReset}
      onClose={close}
      openPopover={openPopover}
    />
  );
};

export default QueryBuilderPopoverPanel;

import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { Slider as MuiSlider } from '@mui/base/Slider';
// eslint-disable-next-line import/no-cycle
import { useStateWithDebounce } from '@/hooks';

const SliderWithInputs = ({ field, value, onChange }) => {
  const minDefaultValue = field.defaultValue?.[0];
  const maxDefaultValue = field.defaultValue?.[1];

  const [sliderValue, setSliderValue] = useStateWithDebounce({
    baseValue: value,
    onChange,
  });

  const minValue = sliderValue?.[0];
  const maxValue = sliderValue?.[1];

  const handleSliderChange = (e, nextValue) => {
    setSliderValue(nextValue);
  };

  const handleMinChange = (event) => {
    const newMin = Number(event.target.value);
    const max = Number(maxValue || maxDefaultValue);
    if (newMin >= field.min) {
      setSliderValue([newMin, max]);
    } else {
      setSliderValue([(minValue || minDefaultValue), max]);
    }
  };

  const handleMaxChange = (event) => {
    const newMax = Number(event.target.value);
    const min = Number(minValue || minDefaultValue);
    if (newMax <= field.max) {
      setSliderValue([min, newMax]);
    } else {
      setSliderValue([min, (maxValue || maxDefaultValue)]);
    }
  };

  return (
    <Box gap={4}>
      <Box display="flex">
        <MuiSlider
          min={field.min}
          max={field.max}
          onChange={handleSliderChange}
          step={field.step || 1}
          value={sliderValue}
          defaultValue={field.defaultValue}
        />
      </Box>
      <Box display="flex" gap={2}>
        <Box display="flex" alignItems="center" flex={1} gap={2}>
          <Typography variant="body1">Min</Typography>
          <TextField
            fullWidth
            type="number"
            variant="outlined"
            value={minValue}
            defaultValue={minDefaultValue}
            onChange={handleMinChange}
            inputProps={{
              step: field.step,
              min: field.min,
              max: field.max,
            }}
          />
        </Box>

        <Box display="flex" alignItems="center" flex={1} gap={2}>
          <Typography variant="body1">Max</Typography>
          <TextField
            fullWidth
            type="number"
            variant="outlined"
            value={maxValue}
            defaultValue={maxDefaultValue}
            onChange={handleMaxChange}
            inputProps={{
              step: field.step,
              min: field.min,
              max: field.max,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SliderWithInputs;

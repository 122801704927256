import React from 'react';
import { Slider as MuiSlider } from '@mui/base/Slider';
import { useStateWithDebounce } from '@/hooks';
import SliderValueLabel from './SliderValueLabel';
import './Slider.scss';

const Slider = ({
  min, max, onChange, step, value, defaultValue, debounceDelay, noSlots,
}) => {
  const [sliderValue, setSliderValue] = useStateWithDebounce({
    baseValue: value,
    onChange,
    debounceDelay,
  });

  const handleChange = (e, nextValue) => {
    setSliderValue(nextValue);
  };

  return (
    <MuiSlider
      min={min}
      max={max}
      onChange={handleChange}
      slots={!noSlots && { valueLabel: SliderValueLabel }}
      step={step || 1}
      value={sliderValue}
      defaultValue={defaultValue}
    />
  );
};

export default Slider;

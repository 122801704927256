import React from 'react';

import { X } from 'react-feather';
import { fieldByParamName } from '@/constants';
import styles from './FilterTag.module.scss';

const FilterTag = ({ content, onDelete, paramName }) => {
  const field = fieldByParamName[paramName];

  return (
    <div className={styles.filterTag}>
      <div
        className={styles.filterTagAction}
        onClick={() => onDelete(paramName)}
        role="button"
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.keycode === 13) onDelete(paramName);
        }}
      >
        <X size={13} />
      </div>
      <span>{field.label}: </span>
      <span className={styles.filterTagContent}>
        {field.renderTag ? field.renderTag(content) : content}
      </span>
    </div>
  );
};

export default FilterTag;

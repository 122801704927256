import { ErrorBoundary } from '@/components';
import { paramNames } from '@/constants';
import PanelBody from './PanelBody';
import PanelContentErrorFallback from './PanelContentErrorFallback';

import styles from './DashboardPanel.module.scss';

const PanelContent = ({
  baseParams,
  panelId,
  panelData,
  panelParams,
  panelSettings,
  panelError,
  isFetching,
  serializedInsightsParams,
  updateHasMoreExplorePages,
  explorePagination,
}) => {
  return (
    <ErrorBoundary FallbackComponent={PanelContentErrorFallback}>
      <div className={styles.panelContent}>
        <PanelBody
          panelId={panelId}
          panelData={panelData}
          panelSettings={panelSettings}
          panelError={panelError}
          filterType={panelParams[paramNames.filterType]}
          isFetching={isFetching}
          serializedInsightsParams={serializedInsightsParams}
          baseParams={baseParams}
          updateHasMoreExplorePages={updateHasMoreExplorePages}
          explorePagination={explorePagination}
        />
      </div>
    </ErrorBoundary>
  );
};

export default PanelContent;

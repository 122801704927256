import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { DashboardPermissionedControl, FieldsModal, Popover } from '@/components';
import { paramNames } from '@/constants';
import { USER_ROLES } from '@/constants/roles';
import FilterTag from './FilterTag';

import getFilterParamNames from '../helpers/getFilterParamNames';
import { getFiltersApplied } from './helpers';
import styles from './FilterTag.module.scss';

const FilterTagsContainer = ({
  params, onParamsChange, onParamDelete, onReset,
}) => {
  const { dashboardActive } = useSelector((state) => state.dashboards);

  const filterParamNames = useMemo(() => getFilterParamNames(params[paramNames.filterType]), [params]);
  const filtersApplied = useMemo(() => getFiltersApplied(params, filterParamNames), [filterParamNames, params]);

  const handleReset = (close) => {
    onReset();
    close();
  };

  return (
    <div className={styles.tagsContainer}>
      <div className={styles.tagsWrapper}>
        {Object.entries(filtersApplied).map(([paramName, content]) => (
          <DashboardPermissionedControl
            key={`permissioned_wrapper_${dashboardActive.id}_${paramName}`}
            dashboardId={dashboardActive.id}
            requiredPermissions={[USER_ROLES.EDITOR]}
          >
            <FilterTag
              key={`${dashboardActive.id}_${paramName}`}
              paramName={paramName}
              content={content}
              onDelete={onParamDelete}
            />
          </DashboardPermissionedControl>
        ))}
      </div>
      <div className={styles.tagsFilterButtonWrapper}>
        <Popover
          color="accent"
          variant="contained"
          size="small"
          className={styles.addFilterTag}
          renderContent={({ close }) => (
            <FieldsModal
              onChange={onParamsChange}
              onReset={() => handleReset(close)}
              params={params}
              paramNames={filterParamNames}
              title="Filters"
            />
          )}
          renderCustomTrigger={({ onClick }) => (
            <DashboardPermissionedControl
              dashboardId={dashboardActive.id}
              requiredPermissions={[USER_ROLES.EDITOR]}
            >
              <Button
                onClick={onClick}
                variant="contained"
                size="small"
                shape="rounded"
                color="accent"
                disableElevation
              >
                <TuneIcon fontSize="small" />
              </Button>
            </DashboardPermissionedControl>
          )}
        />

      </div>
    </div>
  );
};

export default FilterTagsContainer;

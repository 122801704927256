import { AlertTriangle } from 'react-feather';
import { Box } from '@mui/material';
import { Button, Card, ErrorFallback } from '@/components';
import extractErrorMessage from '@/utils/extractErrors';

import styles from './PanelBodyExplore.module.scss';

const BackgroundError = () => {
  return <Box width="100%" height="100%" />;
};

const ErrorCard = ({ error, resetErrorBoundary }) => {
  return (
    <Card
      className={styles.errorContainer}
      title={<><AlertTriangle color="hsl(var(--status-danger))" /> Error:</>}
      actions={[
        <Button variant="link" noPadding href="mailto:saas@qloo.com?subject=query help&body=Hi!, I need help with...">
          Contact Support
        </Button>,
        <Button onClick={resetErrorBoundary}>Retry</Button>,
      ]}
    >
      <div className={styles.errorMessage}>
        <pre>{extractErrorMessage(error)}</pre>
      </div>
    </Card>
  );
};

const CategoryDataErrorFallback = (props) => (
  <Box width="100%" height="412px">
    <ErrorFallback
      backgroundRenderer={() => <BackgroundError />}
      errorRenderer={() => <ErrorCard {...props} />}
    />
  </Box>
);

export default CategoryDataErrorFallback;

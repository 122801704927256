import { captureConsoleIntegration } from '@sentry/integrations';
import { replayIntegration } from '@sentry/react';
import version from './version';

const environment = import.meta.env.VITE_NODE_ENV || 'development';
const SentryDns = import.meta.env.VITE_SENTRY_DSN;
const branch = import.meta.env.VITE_GIT_BRANCH_NAME || environment;

const isProduction = environment === 'production';
const isStaging = environment === 'staging';

const SentryConfig = {
  dsn: SentryDns,
  tracesSampleRate: isProduction || isStaging ? 0.1 : 1.0,
  enabled: isProduction || isStaging,
  environment,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  integrations: [captureConsoleIntegration(), replayIntegration()],
  release: version,

  beforeSend(event) {
    const modifiedEvent = { ...event };
    modifiedEvent.tags = { ...modifiedEvent.tags, branch };
    return modifiedEvent;
  },
};

export default SentryConfig;

import { Box } from '@mui/system';
import RadarIcon from '@mui/icons-material/Radar';
import { InputV2 } from '../Input';
import Slider from '../Slider';

import styles from './MapDrawShape.module.scss';

const RadiusSelector = ({ radius, onChange }) => {
  return (
    <Box display="flex" alignItems="center" gap="8px" paddingLeft="16px" minWidth={250}>
      <RadarIcon fontSize="small" />
      <span>Radius:</span>
      <Slider
        min={1}
        max={800000}
        value={radius}
        onChange={onChange}
        step={1}
        debounceDelay={0}
        noSlots
      />
      <InputV2
        variant="standard"
        color="primary"
        disableUnderline
        value={radius}
        onChange={onChange}
        type="number"
        size="small"
        className={styles.radiusInput}
        min={1}
        max={800000}
        step={100}
      />
    </Box>
  );
};

export default RadiusSelector;

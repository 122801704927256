import React, { useMemo } from 'react';
import { Source } from '@urbica/react-map-gl';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useStateWithDebounce } from '@/hooks';
import { USER_ROLES } from '@/constants/roles';
import DashboardPermissionedControl from '../DashboardPermissionedControl';
import Map from '../Map';
import MapControls from '../MapDrawShape/MapControls';
import HeatmapLayer from './HeatmapLayer';
import HeatmapControls from './HeatmapControls';
import DrawControl from './DrawControl';

import styles from './MapHeatmap.module.scss';

const heatmapToGeojson = (heatmapData) => ({
  type: 'FeatureCollection',
  features: Array.isArray(heatmapData) ? heatmapData.map(({ lat, lon, query }) => ({
    type: 'Feature',
    properties: {
      ...query,
      // eslint-disable-next-line max-len
      // weighted: (query?.affinity_raw && query?.popularity) && (query.affinity_raw * 0.8) + (query.popularity * 0.3),
      affinity: query.affinity * 0.5,
      affinity_rank: query?.affinity_rank,
    },
    geometry: {
      type: 'Point',
      coordinates: [lon, lat, 1],
    },
  })) : [],
});

const MapHeatmap = ({
  data, controls, onControlsChange, onLocationChange, isFetching,
}) => {
  const { dashboardActive } = useSelector((state) => state.dashboards);
  const geoJson = useMemo(() => heatmapToGeojson(data), [data]);
  const baseControls = useMemo(() => ({
    heatmapType: controls?.heatmapType || 'affinity',
    heatmapRange: controls?.heatmapRange && !Number.isNaN(controls?.heatmapRange) ? controls.heatmapRange : 19,
    heatmapOpacity: controls?.heatmapOpacity && !Number.isNaN(controls?.heatmapOpacity) ? controls.heatmapOpacity : 0.6,
  }), [controls]);

  const [heatmapControls, setHeatmapControls] = useStateWithDebounce({
    baseValue: baseControls,
    onChange: onControlsChange,
  });

  const handleChange = (key) => (value) => {
    setHeatmapControls({
      ...heatmapControls,
      [key]: value,
    });
  };

  const isInitialFetching = isFetching && (!data || !data.length);

  // For UI design purposes, we can set this to true to test the collapsible feature
  const isHeatmapControlsCollapsible = false;
  const enableSearch = false; // Needs location with geojson

  return (
    <Box
      className={styles.container}
      sx={{
        '.mapboxgl-ctrl-top-right': {
          paddingTop: isHeatmapControlsCollapsible ? '45px' : 0,
        },
        '.mapboxgl-ctrl-logo': {
          display: enableSearch ? 'none' : 'block',
        },
      }}
    >
      {!isInitialFetching && (
        <Map
          followNewFeatures
          features={geoJson}
          defaultZoom={6}
          isFetching={isFetching}
          onLocationChange={onLocationChange}
        >
          <Source id="heatmapSource" type="geojson" data={geoJson} />
          <HeatmapLayer heatmapControls={heatmapControls} />

          <DashboardPermissionedControl
            dashboardId={dashboardActive.id}
            requiredPermissions={[USER_ROLES.EDITOR]}
          >
            <HeatmapControls
              heatmapControls={heatmapControls}
              onControlsChange={handleChange}
              isCollapsible={isHeatmapControlsCollapsible}
            />
          </DashboardPermissionedControl>

          {enableSearch && (
            <Box
              position="absolute"
              bottom={0}
              left={0}
              width="70%"
              borderRadius={({ shape }) => `0 ${shape.borderRadius}px 0 0`}
              overflow="hidden"
              display="flex"
              bgcolor="background.paper"
            >
              <MapControls location={{}} onLocationSelect={() => {}} onRadiusChange={() => {}} size="small" />
              <DrawControl location={{}} onChange={() => {}} />
            </Box>
          )}

        </Map>
      )}
    </Box>
  );
};

export default MapHeatmap;

import React, { useEffect, useMemo } from 'react';
import { findPriority, useCategoryPerms } from '@v1/lib/usePermissions';
import { useErrorBoundary } from 'react-error-boundary';
import clx from 'clsx';
import { useSelector } from 'react-redux';
import { ErrorBoundary, Skeleton } from '@/components';
import CANONICAL_TAG from '@/utils/canonicalTag';
import { useGetTagsQuery } from '@/store/apis/tagsApi';
import { paramNames } from '@/constants';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import useGPTExplainRecs from '@/hooks/useGPTExplainRecs';
import CategoryDataErrorFallback from '@/screens/Dashboard/DashboardPanel/PanelBodyExplore/CategoryDataErrorFallback';
import { serializeInsightsParams } from '@/utils';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import CategoryData from '@/screens/Dashboard/DashboardPanel/PanelBodyExplore/CategoryData';
import ResumedCategoryData from '@/screens/Dashboard/DashboardPanel/PanelBodyExplore/ResumedCategoryData';
import styles from './PanelBodyExplore.module.scss';

const CategoryDataSkeleton = ({ conciseView }) => (
  <div className={styles.categoryData}>
    <div className={styles.overlay} />
    <div className={styles.categoryHeader}>
      <Skeleton variant="text" width={150} height={30} />
      {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
      <Skeleton variant="rectangular" width={30} height={30} style={{ marginLeft: 'auto' }} />
    </div>
    <Skeleton variant="text" width="100%" height={20} />
    <div className={styles.separator} />
    <Skeleton variant="rectangular" width="100%" height={50} />
    <div className={styles.separator} />
    {!conciseView && <Skeleton variant="rectangular" width="100%" height={150} />}
  </div>
);

const CategoryDataView = ({
  category, onSelect, baseParams, conciseView,
}) => {
  const { showBoundary, resetBoundary } = useErrorBoundary();

  const filters = useMemo(() => ({
    parentType: category, take: 5, tags: CANONICAL_TAG, ...baseParams,
  }), [category, baseParams]);

  const {
    data: tagsResult,
    isLoading: isLoadingTags,
    error: tagsError,
  } = useGetTagsQuery(filters);

  const serializedInsightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: category,
      [paramNames.take]: 3,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, category]);

  const {
    data: entitiesResult,
    isLoading: isLoadingEntities,
    error: insightsError,
    refetch: insightsRefetch,
  } = useGetInsightQuery(serializedInsightsParams);

  const { result: gptResult, isLoading: isLoadingGPT, error: gptError } = useGPTExplainRecs({ category, baseParams });

  useEffect(() => {
    resetBoundary();
    insightsRefetch();
  }, [baseParams]);

  if (insightsError || tagsError || gptError) {
    const errors = [insightsError, tagsError, gptError];
    const errorPath = insightsError?.data?.errors[0]?.path
      || tagsError?.data?.errors[0]?.path || gptError?.data?.errors[0]?.path;
    if (errorPath && errorPath === 'signal.interests.entities') {
      return null;
    }
    const hasDestinationSubtypeError = errors.some(
      (error) => error?.data?.errors?.[0]?.subtype === 'urn:entity:destination',
    );
    if (hasDestinationSubtypeError) {
      return null;
    }
    showBoundary(insightsError?.data?.errors[0] || tagsError?.data?.errors[0] || gptError?.data?.errors[0]);
  }
  if (isLoadingEntities || isLoadingGPT || isLoadingTags) {
    return <CategoryDataSkeleton conciseView={conciseView} />;
  }

  const categoryColor = `var(--category-${formatCategoryUrnToCssVar(category)})`;

  return (
    <>
      {!conciseView && (
        <CategoryData
          category={category}
          onSelect={onSelect}
          tagsResult={tagsResult}
          entitiesResult={entitiesResult}
          gptResult={gptResult}
        />
      )}
      {conciseView && (
        <ResumedCategoryData
          category={category}
          onSelect={onSelect}
          tagsResult={tagsResult}
          entitiesResult={entitiesResult}
          categoryColor={categoryColor}
        />
      )}
    </>
  );
};

const CategoriesDataList = ({
  onSelect,
  baseParams,
}) => {
  const categories = Object.keys(useCategoryPerms()).sort((a, b) => {
    const aPriority = findPriority(a);
    const bPriority = findPriority(b);
    if (aPriority !== bPriority) {
      return aPriority - bPriority;
    }
    return a.localeCompare(b);
  });
  const { isExploreConciseView } = useSelector((state) => state.dashboards);

  return (
    <div className={styles.mainContainer}>
      <div className={clx(
        styles.categoriesDataListGrid,
        { [styles.categoriesDataListGridConcise]: isExploreConciseView },
      )}
      >
        {categories.map((category) => (
          <ErrorBoundary FallbackComponent={CategoryDataErrorFallback} key={category}>
            <CategoryDataView
              baseParams={baseParams}
              category={category}
              onSelect={onSelect}
              conciseView={isExploreConciseView}
            />
          </ErrorBoundary>
        ))}
      </div>
    </div>
  );
};

export default CategoriesDataList;

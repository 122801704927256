import clx from 'clsx';
import { urnsToDisplayName } from '@qloo/categories';
import { ArrowForwardIos } from '@mui/icons-material';
import React from 'react';
import { Button } from '@/components';

import styles from './PanelBodyExplore.module.scss';
import TagsList from '@/components/TagsList';
import EntitiesList from '@/components/Omnisearch/EntitiesList';

const CategoryData = ({
  gptResult, tagsResult, category, onSelect, entitiesResult,
}) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
  <div
    key={category}
    className={clx(
      styles.categoryData,
    )}
    onClick={onSelect(category)}
  >
    <div
      className={clx(styles.overlay)}

    />
    <div
      className={styles.categoryHeader}
    >
      <span>{urnsToDisplayName(category)}</span>
      <Button color="link"><ArrowForwardIos /></Button>
    </div>
    <span className={styles.categoryDescription}>
      {gptResult}
    </span>
    <div className={styles.separator} />
    {tagsResult?.results?.tags && (
      <TagsList tags={tagsResult?.results?.tags} useCategoryColor category={category} />
    )}
    <div className={styles.separator} />
    {entitiesResult?.results?.entities && (
      <EntitiesList
        resetPadding
        entities={entitiesResult.results.entities}
        entitiesGridClassName={styles.entitiesGridClassName}
      />
    )}
  </div>
);

export default CategoryData;

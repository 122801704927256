import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItemIcon, ListItemText, MenuItem,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import FeatureNoAccessTooltip from '@/screens/HomePage/FeatureNoAccessTooltip';
import { useToggle } from '@/hooks';
import { useUpdateDashboardNameMutation } from '@/store/apis/dashboardsApi';

const RenameDialog = ({
  dashboard, open, onClose, onConfirm,
}) => {
  const [dashboardTitle, setDashboardTitle] = useState(dashboard.title);
  const [updateDashboardName, { isLoading }] = useUpdateDashboardNameMutation();

  const handleChange = (e) => {
    setDashboardTitle(e.currentTarget.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await updateDashboardName({ dashboardId: dashboard.dashboardId, title: dashboardTitle }).unwrap();
      onConfirm();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>Rename Dashboard</DialogTitle>
      <DialogContent sx={{ paddingTop: '14px !important' }}>
        <TextField
          required
          fullWidth
          autoFocus
          error={!dashboardTitle}
          label="Title"
          value={dashboardTitle}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      </DialogContent>
      <DialogActions sx={{ padding: '12px 24px' }}>
        <Button onClick={onClose} variant="secondary">Cancel</Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!dashboardTitle}
          variant="contained"
        >
          Rename Dashboard
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const MenuItemRename = ({
  dashboard, disabled, onClose, ...props
}) => {
  const isOpenToggle = useToggle();

  const handleClose = () => {
    isOpenToggle.off();
  };

  return (
    <FeatureNoAccessTooltip disabled={disabled}>
      <MenuItem onClick={isOpenToggle.on} disabled={disabled} {...props}>
        <ListItemIcon><EditIcon /></ListItemIcon>
        <ListItemText>Rename</ListItemText>
      </MenuItem>
      <RenameDialog
        dashboard={dashboard}
        open={isOpenToggle.value}
        onClose={handleClose}
        onConfirm={onClose}
      />
    </FeatureNoAccessTooltip>
  );
};

export default MenuItemRename;

import React, { useState, useEffect } from 'react';
import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DashboardPermissionedControl } from '@/components';
import { useScrollBlock, useUserDashboardPermissions } from '@/hooks';
import { USER_ROLES } from '@/constants/roles';
import { useGetDashboardQuery } from '@/store/apis/dashboardsApi';
import MenuItemFavorite from './MenuItemFavorite';
import MenuItemShare from './MenuItemShare';
import MenuItemRename from './MenuItemRename';
import MenuItemClone from './MenuItemClone';
import MenuItemDelete from './MenuItemDelete';

const MoreOptionsDashboard = ({ dashboard, showFavorite, onConfirmDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [blockScroll, allowScroll] = useScrollBlock();

  const { refetch: refetchCurrentDashboard } = useGetDashboardQuery({ id: dashboard?.id });

  const {
    hasSharePermission,
    hasRenamePermission,
    hasClonePermission,
    hasDeletePermission,
  } = useUserDashboardPermissions(dashboard.id);

  const handleOpen = (event) => {
    event.stopPropagation();
    blockScroll();
    setAnchorEl(event.currentTarget);
    refetchCurrentDashboard();
  };

  const handleClose = () => {
    allowScroll();
    setAnchorEl(null);
  };

  const handleConfirmDelete = () => {
    if (onConfirmDelete) {
      onConfirmDelete();
    }
    handleClose();
  };

  // Enable scrolling when the component is unmounted
  useEffect(() => {
    return () => {
      allowScroll();
    };
  }, [allowScroll]);

  return (
    <div>
      <DashboardPermissionedControl
        dashboardId={dashboard.id}
        requiredPermissions={[USER_ROLES.EDITOR]}
      >
        <IconButton aria-label="settings" onClick={handleOpen}>
          <MoreVertIcon />
        </IconButton>
      </DashboardPermissionedControl>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {!!showFavorite && (
          <MenuItemFavorite dashboard={dashboard} />
        )}
        <MenuItemShare dashboard={dashboard} disabled={!hasSharePermission} />
        <MenuItemRename dashboard={dashboard} disabled={!hasRenamePermission} onClose={handleClose} />
        <MenuItemClone dashboard={dashboard} disabled={!hasClonePermission} />
        <MenuItemDelete dashboard={dashboard} disabled={!hasDeletePermission} onDeleted={handleConfirmDelete} />
      </Menu>
    </div>
  );
};

export default MoreOptionsDashboard;

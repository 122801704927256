import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import clsx from 'clsx';

// Components
import { ErrorBoundary, LockerOverlay } from '@/components';
import { useDebounce, useToggle } from '@/hooks';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import { filterTypes, paramNames } from '@/constants';
import { useDashboardActionsContext } from '@/screens/Dashboard/DashboardActionsProvider';
import { serializeInsightsParams } from '@/utils';
import PanelHeader from './PanelHeader';
import PanelContent from './PanelContent';
import PanelErrorFallback from './PanelErrorFallback';
import PanelFooter from './PanelFooter';

import { useDashboardVeltContext } from '../DashboardVeltProvider';
import { getDataByFilterType } from './helpers';
import { defaultPageOffset, defaultPageSize } from './helpers/constants';
import styles from './DashboardPanel.module.scss';

const PanelErrorFallbackComponent = ({ panelId, ...props }) => <PanelErrorFallback panelId={panelId} {...props} />;

const DashboardPanel = ({ dashboard, isEditingUser, panel }) => {
  const { panelId, settings } = panel;
  const panelRef = useRef(null);
  const { baseParams } = dashboard;
  const filterType = panel.params?.[paramNames.filterType];

  const [offset, setOffset] = useState();
  const [debouncedDelay, setDebounceDelay] = useState(0);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [hasMoreExplorePages, setHasMoreExplorePages] = useState(false);
  const {
    value: hasSelectedCategory,
    set: setHasSelectedCategory,
  } = useToggle(!!settings?.exploreControls?.selectedCategory);
  const [explorePagination, setExplorePagination] = useState({
    [paramNames.offset]: settings?.exploreControls?.offset || defaultPageOffset,
    [paramNames.take]: settings?.exploreControls?.take || defaultPageSize,
  });

  useEffect(
    () => {
      setExplorePagination({
        [paramNames.offset]: settings?.exploreControls?.offset || defaultPageOffset,
        [paramNames.take]: settings?.exploreControls?.take || defaultPageSize,
      });
    },
    [settings?.exploreControls],
  );

  useEffect(() => {
    setHasSelectedCategory(!!settings?.exploreControls?.selectedCategory);
  }, [setHasSelectedCategory, settings?.exploreControls?.selectedCategory]);

  const dashboardActions = useDashboardActionsContext();

  const handleUpdateHasMoreExplorePages = (newValue) => {
    setHasMoreExplorePages(newValue);
  };

  const handleUpdateExplorePagination = (nextValue) => {
    setExplorePagination(nextValue);
    dashboardActions.changePanelSettings({
      panelId,
      nextSettings: {
        exploreControls: {
          ...settings?.exploreControls,
          ...nextValue,
        },
      },
    });
  };

  useEffect(() => {
    if (offset !== panel.params?.[paramNames.offset]) {
      setDebounceDelay(0);
      setOffset(panel.params?.[paramNames.offset]);
    } else {
      setDebounceDelay(1000);
    }
  }, [offset, panel.params]);

  const params = useMemo(() => {
    const result = { ...panel.params };

    if (![filterTypes.heatmap, filterTypes.explore].includes(result[paramNames.filterType])) {
      result[paramNames.offset] = result[paramNames.offset] || defaultPageOffset;
      result[paramNames.take] = result[paramNames.take] || defaultPageSize;
    }

    return result;
  }, [panel.params]);

  const serializedInsightsParams = useMemo(() => {
    const tempParams = { ...params };

    if (tempParams[paramNames.take]) {
      // Get one more record than needed to determine if there are more pages
      tempParams[paramNames.take] += 1;
    }

    if (tempParams[paramNames.filterType] === filterTypes.explore) {
      delete tempParams[paramNames.filterType];
    }

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, params]);

  const debouncedSerialize = useDebounce(serializedInsightsParams, debouncedDelay);

  const {
    data: panelData,
    isLoading,
    isFetching,
    error: panelError,
  } = useGetInsightQuery(debouncedSerialize, {
    skip: filterTypes.explore === params[paramNames.filterType],
  });

  // This ensure that the logo loader is shown for the first time only
  useEffect(() => {
    if (isFirstLoad && !isLoading) {
      setIsFirstLoad(false);
    }
  }, [isFirstLoad, isLoading]);

  const mappedPanelData = useMemo(
    () => getDataByFilterType({ filterType, panelData, params }),
    [filterType, panelData, params],
  );
  const hasMorePages = useMemo(
    () => mappedPanelData?.length > params[paramNames.take],
    [mappedPanelData?.length, params],
  );

  useEffect(() => {
    if (panel.isNew && panelRef.current) {
      panelRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [panel.isNew]);

  const dashboardVelt = useDashboardVeltContext();
  const { getPanelVeltLocation, setVeltLocation } = dashboardVelt;

  const veltLocation = useMemo(
    () => getPanelVeltLocation({ panelId }),
    [panelId],
  );

  useEffect(() => {
    setVeltLocation(veltLocation);
  }, [veltLocation]);

  return (
    <ErrorBoundary
      FallbackComponent={(errorFallbackProps) => <PanelErrorFallbackComponent panelId={panelId} {...errorFallbackProps} />}
    >
      <div className={clsx(['comment-able', styles.panel])} data-velt-location={JSON.stringify(veltLocation)} ref={panelRef}>
        <PanelHeader
          baseParams={baseParams}
          panelId={panelId}
          panelParams={params}
          serializedInsightsParams={debouncedSerialize}
          dashboard={dashboard}
        />
        <PanelContent
          baseParams={baseParams}
          panelId={panelId}
          panelParams={params}
          panelSettings={settings}
          panelData={hasMorePages ? mappedPanelData.slice(0, -1) : mappedPanelData}
          panelError={panelError}
          isFetching={isFetching}
          serializedInsightsParams={debouncedSerialize}
          explorePagination={explorePagination}
          updateHasMoreExplorePages={handleUpdateHasMoreExplorePages}
        />
        <PanelFooter
          panelId={panelId}
          panelParams={params}
          panelError={panelError}
          hasData={!!mappedPanelData.length}
          hasMorePages={hasMorePages}
          hasSelectedCategory={hasSelectedCategory}
          explorePagination={explorePagination}
          hasMoreExplorePages={hasMoreExplorePages}
          updateExplorePagination={handleUpdateExplorePagination}
        />
        <div>{isEditingUser ? `${isEditingUser} is editing` : null}</div>
        <LockerOverlay isLocked={isEditingUser} lockedBy={isEditingUser} />
      </div>
    </ErrorBoundary>
  );
};

export default DashboardPanel;

import { paramNames } from '@/constants';

export const NUMBER_OF_COLUMNS = 6;
export const DEFAULT_PANEL_WIDTH = 3;
export const DEFAULT_PANEL_HEIGHT = 5;
export const MIN_PANEL_HEIGHT = 2;

// TODO: Check if this is removable
export const POPOVERS = {
  demographics: 'demographics',
  interests: 'interests',
  locations: 'locations',
  extras: 'extras',
  [paramNames.filterExternalExists]: paramNames.filterExternalExists,
  ratingRange: 'ratingRange',
  popularityRange: 'popularityRange',
  [paramNames.filterRatingMin]: paramNames.filterRatingMin,
  [paramNames.filterRatingMax]: paramNames.filterRatingMax,
  [paramNames.filterPrice_levelMin]: paramNames.filterPrice_levelMin,
  [paramNames.filterPrice_levelMax]: paramNames.filterPrice_levelMax,
  [paramNames.filterPopularityMin]: paramNames.filterPopularityMin,
  [paramNames.filterPopularityMax]: paramNames.filterPopularityMax,
};

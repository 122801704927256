import clsx from 'clsx';
import React from 'react';
import { Zap } from 'react-feather';
import { Button, Popover } from '@/components';
import { insightTypes, insightTypeLabels } from '@/constants';
import { useDashboardActionsContext } from '../DashboardActionsProvider';
import styles from './DashboardToolbar.module.scss';

const NewInsightPopover = () => {
  const dashboardActions = useDashboardActionsContext();

  const items = [
    {
      id: insightTypes.table,
      label: insightTypeLabels[insightTypes.table],
      onClick: () => dashboardActions.addPanel(),
    },
    {
      id: insightTypes.heatmap,
      label: insightTypeLabels[insightTypes.heatmap],
      onClick: () => dashboardActions.addHeatmapPanel(),
    },
    {
      id: insightTypes.explore,
      label: insightTypeLabels[insightTypes.explore],
      onClick: () => dashboardActions.addExplorePanel(),
    },
  ];

  return (
    <div className={styles.newInsightButtonContainer}>
      <Popover
        variant="text"
        renderTrigger={({ isOpen }) => (
          <div className={clsx({
            [styles.newInsightButton]: true,
            [styles.newInsightButtonOpen]: isOpen,
          })}
          >
            <div className={styles.newInsightButtonIcon}>
              <Zap color="#000000" size={12} />
            </div>
            <span className={styles.newInsightButtonText}>
              New Insight
            </span>
          </div>
        )}
        renderContent={({ close }) => (
          <div>
            {items.map((item) => (
              <Button
                color="secondary"
                variant="text"
                key={item.id}
                onClick={() => {
                  item.onClick();
                  close();
                }}
              >
                {item.label}
              </Button>
            ))}
          </div>
        )}
        size="small"
      />
    </div>
  );
};

export default NewInsightPopover;

import clx from 'clsx';
import { urnsToDisplayName } from '@qloo/categories';
import { ArrowForwardIos } from '@mui/icons-material';

import React from 'react';

import styles from './PanelBodyExplore.module.scss';
import { Stack, Typography } from '@mui/material';

const ResumedCategoryData = ({
  categoryColor, tagsResult, category, onSelect, entitiesResult,
}) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
  <div
    key={category}
    className={clx(
      styles.categoryData,
      styles.categoryDataConcise,
    )}
    onClick={onSelect(category)}
  >
    <div
      className={clx(styles.overlay)}

    />
    <div
      className={clx(styles.categoryHeader, styles.categoryHeaderConcise)}
      style={{
        '--categoryColor': categoryColor,
      }}
    >
      <Typography color={categoryColor}>{urnsToDisplayName(category)}</Typography>
      <Stack
        width={24}
        height={24}
        color="white"
        alignItems="center"
        justifyContent="center"
        padding="7px"
        sx={{ backgroundColor: categoryColor, borderRadius: '50px' }}
      >
        <ArrowForwardIos width={10} />
      </Stack>
    </div>

    {tagsResult?.results?.tags && (
      <div className={styles.listContainer}>
        <span className={styles.listContent}>
          <span className={styles.listLabel}>Tags:</span>
          <span>
            {tagsResult.results.tags.map((tag) => tag.name).join(', ')}
          </span>
        </span>
      </div>
    )}

    <div className={styles.separator} />
    {entitiesResult?.results?.entities && (
      <div className={styles.listContainer}>
        <div className={styles.listContent}>
          <span className={styles.listLabel}>Entities: </span>
          <span>
            {entitiesResult.results.entities.map((entity) => entity.name).join(', ')}
          </span>
        </div>
      </div>
    )}
  </div>
);

export default ResumedCategoryData;

import capitalize from '@/utils/capitalize';

function formatSubCategory(urn) {
  const parts = urn.split(':');

  const segments = parts.slice(4);

  let formattedOutput;

  if (!urn.includes('urn')) {
    formattedOutput = urn;
  } else if (segments.length > 3) {
    formattedOutput = `${segments[0].charAt(0).toUpperCase() + segments[0].slice(1)} / `
      + `${segments[1].charAt(0).toUpperCase() + segments[1].slice(1)} / ... / `
      + `${segments[segments.length - 1].charAt(0).toUpperCase() + segments[segments.length - 1].slice(1)}`;
  } else {
    formattedOutput = segments.map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1)).join(' / ');
  }

  return capitalize(formattedOutput.replace(/_/g, ' '));
}

export default formatSubCategory;

import OpacityIcon from '@mui/icons-material/Opacity';
import Slider from '../Slider';
import { InputV2 } from '../Input';

import styles from './MapHeatmap.module.scss';

const HeatmapOpacitySlider = ({ value, onChange }) => {
  const handleInputChange = (e) => {
    const newValue = Number(e);
    onChange(newValue);
  };

  return (
    <div className={styles.sliderContainer}>
      <OpacityIcon fontSize="small" />
      <Slider
        min={0}
        max={1}
        value={value}
        onChange={handleInputChange}
        step={0.01}
        debounceDelay={0}
        noSlots
      />
      <InputV2
        variant="standard"
        color="primary"
        disableUnderline
        value={value}
        onChange={handleInputChange}
        type="number"
        size="small"
        className={styles.heatmapInput}
        min={0}
        max={1}
        step={0.01}
      />
    </div>
  );
};

export default HeatmapOpacitySlider;

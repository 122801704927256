import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Input from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import * as Sentry from '@sentry/react';
import { useSearchMapQuery } from '@/store/apis/openstreetmapApi';
import { useDebounce, useDescribeLocation, useToggle } from '@/hooks';
import { geoJsonAreaSize } from '@/utils';
import GeoIconView from '../GeoIconView';

import styles from './MapDrawShape.module.scss';

const LocationOption = ({ option, params }) => (
  <div {...params}>
    <Box display="flex" alignItems="start" gap="16px">
      <div className={styles.geoIcon}>
        <GeoIconView geojson={option?.geometry} />
      </div>
      <Box display="flex" flexDirection="column">
        <h3>{ option?.properties?.display_name }</h3>
        <p>
          { option?.geometry?.type } { option?.geometry?.type !== 'Point' && geoJsonAreaSize(option, { unit: 'meters' }) }
        </p>
      </Box>
    </Box>
  </div>
);

const LocationSelector = ({
  location,
  filterResults = (r) => r,
  onSelected = () => {},
  className,
  color,
  size,
}) => {
  const [search, setSearch] = useState();
  const hasSelectedToggle = useToggle(!!location.value);
  const debouncedSearch = useDebounce(search, 250);
  const { data: results, isLoading, isFetching } = useSearchMapQuery(debouncedSearch, { skip: !debouncedSearch });

  const describedLocation = useDescribeLocation(location);

  const handleChange = useCallback((e, result) => {
    if (!result) {
      Sentry.captureMessage(`Location data is missing, which is unexpected. Event details: ${JSON.stringify(e)}`);
      return;
    }

    hasSelectedToggle.on();
    onSelected(result);
  }, [hasSelectedToggle, onSelected]);

  const handleInputChange = useCallback((e) => {
    hasSelectedToggle.off();
    setSearch(e.target.value);
  }, [hasSelectedToggle, setSearch]);

  return (
    <>
      <Box padding="12px" display="flex"><SearchIcon fontSize={size} /></Box>
      <Autocomplete
        id="mapbox-place-finder"
        className={className}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option?.properties?.display_name)}
        filterOptions={filterResults}
        options={Array.isArray(results?.features) && !isLoading && !isFetching ? results.features : []}
        loading={isLoading || isFetching}
        loadingText="Searching..."
        noOptionsText={!search ? 'Start typing to search' : 'No results found'}
        // autoComplete // TODO: Check this
        // includeInputInList
        clearOnEscape
        blurOnSelect
        onChange={handleChange}
        value={describedLocation?.displayLabel}
        renderInput={(params) => (
          <Input
            {...params}
            placeholder="Find a country, city, or neighborhood"
            variant="standard"
            fullWidth
            onChange={handleInputChange}
            className={clsx({
              [styles.locationInput]: true,
              [styles.colorBlue]: !!color && color === 'blue',
              [styles.colorPurple]: !!color && color === 'purple',
              [styles.locationSelected]: !!color && hasSelectedToggle.value,
              [styles.smallInput]: !!size && size === 'small',
            })}
            InputProps={{
              ...params.InputProps,
              endAdornment: null, // Remove endAdornment
              disableUnderline: true,
            }}
          />
        )}
        renderOption={(params, option) => (
          <LocationOption option={option} params={params} />
        )}
        componentsProps={{
          paper: {
            sx: {
              borderRadius: 'var(--border-radius)',
            },
          },
          popper: {
            className: styles.locationPopper,
            placement: 'bottom-start',
          },
        }}
      />
    </>
  );
};

export default LocationSelector;

import React, { useMemo } from 'react';
import { X } from 'react-feather';
import { fieldByParamName } from '@/constants';
import { getIsFieldValueEmpty, hasLocationParam } from '@/utils';
import Button from '../Button';
import FieldsModalChips from './FieldsModalChips';
import SingleParamBody from './SingleParamBody';
import MultipleParamsBody from './MultipleParamsBody';
import getChips from './getChips';
import styles from './FieldsModal.module.scss';
import { POPOVERS } from '@/screens/Dashboard/helpers/constants';

const defaultRenderIndicator = (value) => (
  getIsFieldValueEmpty(value) ? null : <div className={styles.labelIndicatorDot} />
);

const FieldsModal = ({
  baseParams = {},
  paramNames,
  params,
  renderIndicator = defaultRenderIndicator,
  title,
  color,
  onChange,
  onReset,
  onClose,
  openPopover,
}) => {
  const validParamNames = paramNames.filter((paramName) => fieldByParamName[paramName]);

  const hasLocation = hasLocationParam(paramNames);

  const handleChange = (key) => (value) => {
    onChange({ key, value });
  };

  const chips = useMemo(
    () => getChips({
      baseParams, color, onChange, paramNames, params,
    }),
    [baseParams, color, paramNames, params],
  );

  const isSingleParam = validParamNames.length === 1;
  const isInterestsPopover = openPopover === POPOVERS.interests;

  return (
    <div className={styles.container}>
      {!isInterestsPopover && (
        <div className={styles.header}>
          <div className={styles.headerTop}>
            <div className={styles.title}>{title}</div>
            {hasLocation && isSingleParam && (
              <Button variant="text" color="accent" onClick={onClose}>
                <X size={16} />
              </Button>
            )}
          </div>
          {(!isSingleParam || (isSingleParam && !hasLocation)) && <FieldsModalChips chips={chips} />}
        </div>
      )}
      {isSingleParam ? (
        <SingleParamBody
          isLocation={hasLocation}
          paramName={validParamNames[0]}
          baseParams={baseParams}
          params={params}
          paramNames={paramNames}
          onChange={handleChange}
          onReset={onReset}
          showResetButton={!isInterestsPopover}
          resetPadding={hasLocation || isInterestsPopover}
        />
      ) : (
        <MultipleParamsBody
          params={params}
          paramNames={validParamNames}
          baseParams={baseParams}
          renderIndicator={renderIndicator}
          onChange={handleChange}
          onReset={onReset}
        />
      )}
    </div>
  );
};

export default FieldsModal;

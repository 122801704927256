import React from 'react';
import { styled } from '@mui/system';
import { USER_ROLES } from '@/constants/roles';
import { useUserDashboardPermissions } from '@/hooks';

const Container = styled('div')(({ userIsAuthorized }) => ({
  pointerEvents: userIsAuthorized ? 'auto' : 'none',
  opacity: userIsAuthorized ? 1 : 0.7,
}));

const DashboardPermissionedControl = ({
  requiredPermissions = [],
  dashboardId,
  hideIfUnauthorized = false,
  children,
}) => {
  const { permissionsBitMap } = useUserDashboardPermissions(dashboardId);

  const userIsAuthorized = permissionsBitMap[USER_ROLES.OWNER]
                    || requiredPermissions.some((permission) => permissionsBitMap[permission]);

  const handleClick = (event) => {
    if (!userIsAuthorized) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <Container
      key={`permissioned_${dashboardId}`}
      userIsAuthorized={userIsAuthorized}
      onClick={handleClick}
    >
      {hideIfUnauthorized && !userIsAuthorized ? null : children}
    </Container>
  );
};

export default DashboardPermissionedControl;

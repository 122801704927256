import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { filterTypes, paramNames } from '@/constants';
import getPanelHeaderTitle from '@/utils/getPanelHeaderTitle';
import { DashboardPermissionedControl } from '@/components';
import { USER_ROLES } from '@/constants/roles';
import { toggleExploreConciseView } from '@/store/slices/dashboardsSlice';
import { useDashboardVeltContext } from '../DashboardVeltProvider';

import PanelContextMenu from './PanelContextMenu';
import PanelParams from './PanelParams';
import styles from './DashboardPanel.module.scss';

const PanelHeader = ({
  panelId, baseParams, panelParams, serializedInsightsParams,
}) => {
  const dispatch = useDispatch();
  const { dashboardActive, isExploreConciseView } = useSelector((state) => state.dashboards);
  const dashboardVelt = useDashboardVeltContext();
  const { isCommentingToggle } = dashboardVelt;
  const filterType = panelParams?.[paramNames.filterType];

  const toggleExploreView = () => {
    dispatch(toggleExploreConciseView());
  };

  return (
    <div className={styles.panelHeaderWrapper}>
      <div className={styles.panelHeader}>
        <div
          className={clsx({
            'panel-drag-handle': !isCommentingToggle.value,
            [styles.panelHeaderLeft]: true,
          })}
          data-velt-sync-access="true"
        >
          <span>{getPanelHeaderTitle(filterType)}</span>
        </div>

        <DashboardPermissionedControl
          dashboardId={dashboardActive.id}
          requiredPermissions={[USER_ROLES.EDITOR]}
        >
          <div className={styles.panelHeaderRight}>
            {filterType === filterTypes.explore && (
              <div>
                <FormControlLabel
                  control={<Switch checked={isExploreConciseView} onChange={toggleExploreView} name="viewMode" />}
                  label="Concise View"
                  style={{ display: 'none' }} // hide for now
                />
              </div>
            )}
            <PanelContextMenu panelId={panelId} serializedInsightsParams={serializedInsightsParams} />
          </div>
        </DashboardPermissionedControl>
      </div>
      <PanelParams
        baseParams={baseParams}
        panelId={panelId}
        panelParams={panelParams}
      />
    </div>
  );
};

export default PanelHeader;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { api } from '@/constants';
import { getHash } from '@/utils';

export const insightsApi = createApi({
  reducerPath: 'insightsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${api.hostname}/api/${api.server}/v2/insights` }),
  tagTypes: ['Insight'],
  endpoints: (builder) => ({
    getInsight: builder.query({
      query: (query) => `?${query}`,
      providesTags: (result, error, arg) => [{ type: 'Insight', id: getHash(arg) }],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const {
  useGetInsightQuery,
} = insightsApi;

import React from 'react';
import { useSelector } from 'react-redux';
import { VeltComments, VeltCursor } from '@veltdev/react';

import { USER_ROLES } from '@/constants/roles';
import { DashboardPermissionedControl } from '@/components';
import DashboardToolbar from '../DashboardToolbar/DashboardToolbar';
import DashboardLayout from '../DashboardLayout/DashboardLayout';

import { useDashboardVeltContext } from '../DashboardVeltProvider';
import styles from './DashboardBody.module.scss';

const DashboardBody = ({ dashboard }) => {
  const dashboardVelt = useDashboardVeltContext();
  const { isVeltInitialized } = dashboardVelt;
  const { isDarkMode } = useSelector((state) => state.app);

  return (
    <>
      <div className={styles.container}>
        <DashboardPermissionedControl
          dashboardId={dashboard.id}
          requiredPermissions={[USER_ROLES.EDITOR]}
          hideIfUnauthorized
        >
          <DashboardToolbar />
        </DashboardPermissionedControl>
        <DashboardLayout dashboard={dashboard} />
      </div>
      {isVeltInitialized ? <VeltCursor /> : null}
      {isVeltInitialized ? (
        <VeltComments
          allowedElementClassNames={['comment-able']}
          onCommentAdd={(event) => {
            console.log('new comment:', event);
          }}
          status={false}
          darkMode={isDarkMode}
        />
      ) : null}
    </>
  );
};

export default DashboardBody;

import { useEffect, useState } from 'react';
import { ellipsisText } from '@/utils';
import getDescribeLocation from '../utils/getDescribeLocation';

// For legacy support
// Old locations are not described (they are missing the `label` and/or 'displayLabel` fields)
const useDescribeLocation = (location) => {
  const [nextLocation, setNextLocation] = useState({
    ...location ?? {},
    label: location?.value ? ellipsisText(JSON.stringify(location.value)) : '',
  });

  useEffect(() => {
    const request = async () => {
      const result = await getDescribeLocation(location);

      if (result.label) {
        setNextLocation(result);
      }
    };

    request();

    return () => {
      setNextLocation(null);
    };
  }, [location]);

  return nextLocation;
};

export default useDescribeLocation;
